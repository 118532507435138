import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import secondproduct from './img/secondproduct.png';
import thirdproduct from './img/thirdproduct.png';
import fourthproduct from './img/fourthproduct.png';
import ffirst from './img/ffirst.png';
import fsecond from './img/fsecond.png';
import fthird from './img/fthird.png';
import sfirst from './img/sfirst.png';
import ssecond from './img/ssecond.png';
import tfirst from './img/tfirst.png';
import tsecond from './img/tsecond.png';
import tthird from './img/tthird.png';
import first from './img/first.png';
import fifth from './img/fifth.png';
import six from './img/six.png';
import pfirst from './img/pfirst.png';
import psecond from './img/psecond.png';
import pthird from './img/pthird.png';
import fifirst from './img/fifirst.png';
import fisecond from './img/fisecond.png';
import fithird from './img/fithird.png';
import sifirst from './img/sifirst (2).png';
import sisecond from './img/sisecond (2).png';
import sithird from './img/sithird.png';
import seventh from './img/7th8910th/seventh.png';
import eight from './img/7th8910th/eight.png';
import ninth from './img/7th8910th/ninth.png';
import tenth from './img/7th8910th/tenth.png';
import seventh1 from './img/7th8910th/seventh1 (2).png';
import seventh2 from './img/7th8910th/seventh2 (2).png';
import seventh3 from './img/7th8910th/seventh3 (2).png';
import seventh4 from './img/7th8910th/seventh4 (2).png';
import seventh5 from './img/7th8910th/seventh5 (2).png';
import  eight1 from './img/7th8910th/eight1.png';
import  eight2 from './img/7th8910th/eight2.png';
import  eight3 from './img/7th8910th/eight3.png';
import  eight4 from './img/7th8910th/eight4.png';
import  eight5 from './img/7th8910th/eight5.png';
import  ninth1 from './img/7th8910th/ninth1.png';
import  ninth2 from './img/7th8910th/ninth2.png';
import  ninth3 from './img/7th8910th/ninth3.png';
import  ninth4 from './img/7th8910th/ninth4.png';
import  tenth1 from './img/7th8910th/tenth1.png';
import  tenth2 from './img/7th8910th/tenth2.png';
import  tenth3 from './img/7th8910th/tenth3.png';
import  tenth4 from './img/7th8910th/tenth4.png';
import  tenth5 from './img/7th8910th/tenth5.png';




const MyComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]); // State to keep track of the current set of images

  const handleShowModal = (images) => {
    setCurrentImages(images); // Set the current set of images when the modal is opened
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={first} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Stunning Georgette Maxi Dress by Sheetal Associates"
</Card.Title>
                <Card.Text>

                "Discover effortless elegance with Sheetal Associates' Georgette Solid Casual Maxi Dress. Designed for comfort and style, this dress is perfect for any occasion. Elevate your wardrobe with its flattering fit and flare silhouette, crafted from premium georgette fabric. Make a statement wherever you go with this versatile and chic ensemble."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44Q2APn" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([pfirst,psecond,pthird])} style={{ backgroundColor: '#98BE5F', color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={secondproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Discover Elegance: Gosriki Women's Cotton Blend Ensemble"</Card.Title>
                <Card.Text>
                  Introducing Gosriki's Women's Cotton Blend Ensemble: Elevate your style with our embroidered straight kurta paired with comfortable pants and a delicate dupatta. Experience elegance and comfort combined in one ensemble. Shop now!
                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3QxuIAw" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([sfirst, ssecond])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={thirdproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style: GoSriKi's Printed Cotton Blend Kurta Set"</Card.Title>
                <Card.Text>
                "Elevate your wardrobe with GoSriKi's latest collection featuring the Women's Cotton Blend Straight Printed Kurta Set. Crafted for both style and comfort, this ensemble includes a beautifully printed kurta paired with coordinating pants and a dupatta. Experience effortless elegance and embrace versatile fashion with GoSriKi."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44Bq9v0" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([tfirst,tsecond,tthird])} style={{ backgroundColor: '#98BE5F', color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={fourthproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with FABNEX Women's Embroidered Kurta Set"</Card.Title>
                <Card.Text>
                "Indulge in sophistication and elevate your everyday look with FABNEX Women's Embroidered Kurta Set. Crafted with exquisite embroidery and designed for comfort, this versatile ensemble effortlessly combines elegance and style. Perfect for any occasion, explore our collection of Women's Kurta Sets and discover your signature style."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3wilOQN" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([ffirst, fsecond,fthird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={fifth} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style: GoSriKi's Printed Cotton Blend Kurta Set"</Card.Title>
                <Card.Text>
                "Elevate your wardrobe with GoSriKi's latest collection featuring the Women's Cotton Blend Straight Printed Kurta Set. Crafted for both style and comfort, this ensemble includes a beautifully printed kurta paired with coordinating pants and a dupatta. Experience effortless elegance and embrace versatile fashion with GoSriKi."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/4aa3FSV" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([fifirst,fisecond,fithird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={six} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with FABNEX Women's Embroidered Kurta Set"</Card.Title>
                <Card.Text>
                "Indulge in sophistication and elevate your everyday look with FABNEX Women's Embroidered Kurta Set. Crafted with exquisite embroidery and designed for comfort, this versatile ensemble effortlessly combines elegance and style. Perfect for any occasion, explore our collection of Women's Kurta Sets and discover your signature style."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UUdPCZ" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([sifirst, sisecond,sithird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>


      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={seventh} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with iStyle: Belted Wrap Dress with Bishop Sleeves"

</Card.Title>
                <Card.Text>
                "Elevate your style with iStyle's Belted Wrap Dress with Bishop Sleeves. This midi dress exudes timeless elegance with its surplice neck, high waist, and flared hem. Perfect for any occasion, it's the epitome of chic sophistication. Shop now for the ultimate in women's fashion."
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UYP2O4" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([seventh1,seventh2,seventh3,seventh4,seventh5])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={eight} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Professional Style with KOTTY: Women's Solid Relaxed Fit Co-ord Blazer and Trouser Set"

</Card.Title>
                <Card.Text>
                "Elevate your professional wardrobe with KOTTY's Women's Solid Relaxed Fit Co-ord Blazer and Trouser Set. Crafted for comfort and style, this ensemble offers a polished look for the modern woman on the go. Upgrade your workwear with KOTTY today."
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UJNsOx" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([eight1, eight2,eight3,eight4,eight5])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={ninth} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with Lymio: Western Dresses for Women"

</Card.Title>
                <Card.Text>
                "Discover the epitome of elegance with Lymio's Western Dresses for Women. From casual chic to statement-making sophistication, our curated collection offers versatile styles for every occasion. Elevate your wardrobe with Lymio today."
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44J58i0" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([ninth1,ninth2,ninth3,ninth4])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={tenth} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style with Leriya Fashion: Western Dress Co-ord Sets for Women"

</Card.Title>
                <Card.Text>
                "Discover contemporary elegance with Leriya Fashion's Western Dress Co-ord Sets for women. Crafted with precision and style, our sets offer versatility and sophistication for every occasion. Explore our curated collection today and redefine your wardrobe with Leriya Fashion."

</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3wyds7J" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([tenth1,tenth2,tenth3,tenth4,tenth5])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>



      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={currentImages[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} className="img-fluid mr-2" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
            Prev
          </Button>
          <Button variant="primary" onClick={handleNextImage} disabled={currentImageIndex === currentImages.length - 1}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyComponent;
