// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar1 from './Navbar1';
import Section1 from './Section1';
import Product from './Product';
import Footer from './Footer';
import { Route, Routes, Navigate } from 'react-router-dom';
import Dress from './dress';
import Homekitchen from './homekitchen';
import Beauty from './Beauty';
import Electronics from './electronics';

function App() {
  return (
    <>
      <Navbar1 />
    
      <Routes>
        <Route exact path='/' Component={Section1} />
        <Route exact path='/product' Component={Product} />
        <Route exact path='/dress' Component={Dress} />
        <Route exact path='/homekitchen' Component={Homekitchen} />
        <Route exact path='/beauty' Component={Beauty} />
        <Route exact path='/electronics' Component={Electronics} />

        <Route path="/section1" element={<Navigate to="/" />} />
      </Routes>
      <Footer />




    </>
  );
}

export default App;
