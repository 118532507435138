import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import logo2 from '../src/img/logo2.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function HeaderAndFooterExample() {
  return (
    <Card className="text-center" style={{ backgroundColor: '#B9BABF' }}>
      {/* <Card.Header>Featured</Card.Header> */}
      <Card.Body >
        <img src={logo2} className="footer-logo" style={{ width: '20%' }} />

        <Card.Title><h4>Wishtaar</h4></Card.Title>
        <Card.Text>
          <h6>Contact Us </h6>
          <FontAwesomeIcon icon={faEnvelope} style={{ height: '35px' }} />

          <h6>wishtaar@gmail.com</h6>
          <FontAwesomeIcon icon={faInstagram} style={{ color: 'red', height: '35px' }} />     <br />
          <Link to="https://www.instagram.com/wishtaar?igsh=MTRsdHFsbXhkNWI0cg==" style={{ color: '#313235', textDecoration: 'none' }}>
            <h6>Instagram</h6>
          </Link>
        </Card.Text>

        <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
          <Link to="/product" style={{ color: '#313235', textDecoration: 'none' }}>
            Explore
          </Link>
        </Button> 
        </Card.Body>
      <Card.Footer className="text-muted">© 2024 Wishtaar. All Rights Reserved</Card.Footer>
    </Card>
  );
}

export default HeaderAndFooterExample;