import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import powerbank from './img/power bank/powerbank.png';
import powerbank1 from './img/power bank/powerbank1.png';
import powerbank2 from './img/power bank/powerbank2.png';
import cell1 from './img/cell/cell1.png';
import cell2 from './img/cell/cell2.png';
import first from './img/mobile cover/first.png'
import second from './img/mobile cover/second.png'
import cell from '../src/img/cell/cell.png'

const MyComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]); // State to keep track of the current set of images

  const handleShowModal = (images) => {
    setCurrentImages(images); // Set the current set of images when the modal is opened
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={cell} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Power Up Your Devices with Duracell Alkaline AA Batteries – Pack of 10!"
</Card.Title>
                <Card.Text>

                Keep your devices powered with Duracell Alkaline AA Batteries! Trusted worldwide for long-lasting performance. Pack of 10 ensures you're always prepared. Don't wait – stock up toda
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UDK8oh" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([cell1,cell2])} style={{ backgroundColor: '#98BE5F', color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={powerbank} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Duracell 20000mAh Slim Power Bank: Charge 3 Devices Simultaneously!"</Card.Title>
                <Card.Text>
                Power up to three devices at once with the Duracell 20000mAh Slim Power Bank. Lightning-fast charging for iPhones, Androids, smartwatches, and more. Stay charged on-the-go!
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3QGO8De" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([powerbank1,powerbank2])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
        <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={second} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Tobfit Silicone Phone Case for iPhone 12 - Navy Blue"
</Card.Title>
                <Card.Text>

                Shield your iPhone 12 with the Tobfit Silicone Phone Case, crafted for both style and durability. Designed to fit the contours of the iPhone 12 perfectly, this soft, yet sturdy navy blue case offers reliable protection against scratches and shocks. Its sleek design adds a touch of elegance, making it suitable for both men and women. Elevate your phone's defense without compromising on style with Tobfit Silicone Phone Case.
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44GlHv5" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([cell1,cell2])} style={{ backgroundColor: '#98BE5F', color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={first} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"LIRAMARK Liquid Silicone Soft Back Cover Case for Samsung Galaxy S23 Ultra 5G - Midnight Blue"</Card.Title>
                <Card.Text>
                Introducing the LIRAMARK Liquid Silicone Soft Back Cover Case in Midnight Blue for the Samsung Galaxy S23 Ultra 5G. This sleek and durable case offers reliable protection against scratches and bumps while providing a comfortable grip. Upgrade your device's defense with style.







</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3yu2rVj" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([powerbank1,powerbank2])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={thirdproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style: GoSriKi's Printed Cotton Blend Kurta Set"</Card.Title>
                <Card.Text>
                "Elevate your wardrobe with GoSriKi's latest collection featuring the Women's Cotton Blend Straight Printed Kurta Set. Crafted for both style and comfort, this ensemble includes a beautifully printed kurta paired with coordinating pants and a dupatta. Experience effortless elegance and embrace versatile fashion with GoSriKi."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UDK8oh" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([tfirst,tsecond,tthird])} style={{ backgroundColor: '#98BE5F', color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={fourthproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with FABNEX Women's Embroidered Kurta Set"</Card.Title>
                <Card.Text>
                "Indulge in sophistication and elevate your everyday look with FABNEX Women's Embroidered Kurta Set. Crafted with exquisite embroidery and designed for comfort, this versatile ensemble effortlessly combines elegance and style. Perfect for any occasion, explore our collection of Women's Kurta Sets and discover your signature style."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3wilOQN" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([ffirst, fsecond,fthird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
      {/* <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={fifth} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style: GoSriKi's Printed Cotton Blend Kurta Set"</Card.Title>
                <Card.Text>
                "Elevate your wardrobe with GoSriKi's latest collection featuring the Women's Cotton Blend Straight Printed Kurta Set. Crafted for both style and comfort, this ensemble includes a beautifully printed kurta paired with coordinating pants and a dupatta. Experience effortless elegance and embrace versatile fashion with GoSriKi."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/4aa3FSV" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([fifirst,fisecond,fithird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={six} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with FABNEX Women's Embroidered Kurta Set"</Card.Title>
                <Card.Text>
                "Indulge in sophistication and elevate your everyday look with FABNEX Women's Embroidered Kurta Set. Crafted with exquisite embroidery and designed for comfort, this versatile ensemble effortlessly combines elegance and style. Perfect for any occasion, explore our collection of Women's Kurta Sets and discover your signature style."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UUdPCZ" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([sifirst, sisecond,sithird])} style={{ backgroundColor: '#98BE5F',color:'#313235',border:'none',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}



      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={currentImages[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} className="img-fluid mr-2" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
            Prev
          </Button>
          <Button variant="primary" onClick={handleNextImage} disabled={currentImageIndex === currentImages.length - 1}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyComponent;
