import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import firstproduct from './img/firstproduct.png';
import secondproduct from './img/secondproduct.png';
import thirdproduct from './img/thirdproduct.png';
import fourthproduct from './img/fourthproduct.png';
import ffirst from './img/ffirst.png';
import fsecond from './img/fsecond.png';
import fthird from './img/fthird.png';
import sfirst from './img/sfirst.png';
import ssecond from './img/ssecond.png';
import tfirst from './img/tfirst.png';
import tsecond from './img/tsecond.png';
import tthird from './img/tthird.png';
import cfirst from './img/cfirst.png';
const MyComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]); // State to keep track of the current set of images

  const handleShowModal = (images) => {
    setCurrentImages(images); // Set the current set of images when the modal is opened
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={firstproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Unlock Brighter, Even-Toned Skin: Cetaphil's Night Comfort Cream Review"</Card.Title>
                <Card.Text>
                  Introducing Cetaphil Brightening Night Comfort Cream: The ultimate solution for dark spots and uneven skin tone. Enriched with hydrating hyaluronic acid and skin-brightening niacinamide, this dermatologist-recommended, fragrance-free formula unveils brighter, healthier skin.
                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3wo8cDu" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([cfirst])} style={{ color:'#313235',border:'none', backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={secondproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Discover Elegance: Gosriki Women's Cotton Blend Ensemble"</Card.Title>
                <Card.Text>
                  Introducing Gosriki's Women's Cotton Blend Ensemble: Elevate your style with our embroidered straight kurta paired with comfortable pants and a delicate dupatta. Experience elegance and comfort combined in one ensemble. Shop now!
                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3QxuIAw" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([sfirst, ssecond])} style={{  color:'#313235',border:'none',backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={thirdproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Style: GoSriKi's Printed Cotton Blend Kurta Set"</Card.Title>
                <Card.Text>
                "Elevate your wardrobe with GoSriKi's latest collection featuring the Women's Cotton Blend Straight Printed Kurta Set. Crafted for both style and comfort, this ensemble includes a beautifully printed kurta paired with coordinating pants and a dupatta. Experience effortless elegance and embrace versatile fashion with GoSriKi."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44Bq9v0" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([tfirst,tsecond,tthird])} style={{ color:'#313235',border:'none', backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={fourthproduct} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Elevate Your Wardrobe with FABNEX Women's Embroidered Kurta Set"</Card.Title>
                <Card.Text>
                "Indulge in sophistication and elevate your everyday look with FABNEX Women's Embroidered Kurta Set. Crafted with exquisite embroidery and designed for comfort, this versatile ensemble effortlessly combines elegance and style. Perfect for any occasion, explore our collection of Women's Kurta Sets and discover your signature style."                </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3wilOQN" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([ffirst, fsecond,fthird])} style={{ color:'#313235',border:'none',backgroundColor: '#98BE5F', marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>


      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={currentImages[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} className="img-fluid mr-2" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
            Prev
          </Button>
          <Button variant="primary" onClick={handleNextImage} disabled={currentImageIndex === currentImages.length - 1}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyComponent;
