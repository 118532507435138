import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo2 from '../src/img/logo2.jpg';
import { NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Navbar1 = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => setExpanded(!expanded);
  const closeNavbar = () => setExpanded(false);

  return (
    <div>
      <Navbar expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/" onClick={closeNavbar}>
            <img
              src={logo2}
              width="50%" // Set width to 'auto' to maintain aspect ratio
              height="30" // Set height to desired height
              className="d-inline-block align-top"
              alt="Logo"
            />
            Wishtaar
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="mr-auto" onSelect={closeNavbar}>
              <NavLink to="/section1" className="nav-link" activeClassName="active">Home</NavLink>
              <NavDropdown title="Product" id="basic-nav-dropdown" onSelect={closeNavbar}>
                <NavDropdown.Item>
                  <NavLink to="/dress" className="nav-link" activeClassName="active">Dress</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/homekitchen" className="nav-link" activeClassName="active">Home/Kitchen</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/beauty" className="nav-link" activeClassName="active">Beauty</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to="/electronics" className="nav-link" activeClassName="active">Electronics/Accessories</NavLink>
                </NavDropdown.Item>
                {/* Add more dropdown items as needed */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbar1;
