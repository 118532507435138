import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import img5 from '../src/img/img5.jpg';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import img4 from '../src/img/img4';

function GroupExample() {
    return (
        <>
            <div className='solution1'>
                <div className="px-3">
                    <CardGroup className='card1'>
                        <Card className="border-0">
                            <Card.Img variant="top mt-4" src={img5} className="img-fluid" style={{ maxHeight: '64vh' }} />
                        </Card>
                        <Card className="border-0 ">
                            <Card.Body>
                                <Card.Text>
                                    <h5>Welcome to Wishtaar</h5>
                                    <h6>Discover, Shop, Save!</h6>
                                    At Wishtaar, we're your one-stop destination for finding the best deals, discounts, and recommendations across a wide range of products and services. Whether you're a savvy shopper, a bargain hunter, or simply looking for inspiration, we've got you covered!
                                    <br /><br />
                                    <h5>Shop with Confidence </h5>

                                    At Wishtaar, trust and transparency are paramount. We offer a seamless shopping experience supported by reliable information and trustworthy recommendations. Our comprehensive product reviews, detailed buying guides, and authentic customer testimonials ensure confident decision-making. Plus, our dedicated customer support team is here to assist you at every step. Join our community today for hassle-free shopping like never before!                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="border-0" style={{ backgroundColor: '#FFFFFF' }}>
                                {/* <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                                    <Link to="/product" style={{ color: '#313235', textDecoration: 'none' }}>
                                        Button
                                    </Link>
                                </Button> */}
                            </Card.Footer>
                        </Card>
                    </CardGroup>
                </div>
                <div className="px-3">
                    <CardGroup className='section2 mt-5'>
                        <Card className="border-0">
                            <Card.Body>
                                <Card.Text>
                                    <h5>Explore Our Categories</h5>
                                    Electronics: From smartphones and laptops to smart home devices and accessories, find the latest gadgets and tech innovations here.<br />
                                    Fashion: Stay stylish and on-trend with our selection of clothing, footwear, accessories, and beauty products.<br />
                                    Home & Lifestyle: Enhance your living space with furniture, decor, kitchen essentials, and wellness products for a happier, healthier lifestyle.<br />
                                    Travel: Plan your next adventure with our travel guides, booking services, and insider tips on destinations, accommodations, and activities.<br />
                                    <br />
                                    <h5>Start Saving & Earning Today</h5>

                                    Ready to shop smarter and earn rewards? Browse our categories, explore our recommendations, and start saving money while earning rewards with every purchase. Join us at Wishtaar and unlock a world of savings, convenience, and rewards!
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="border-0" style={{ backgroundColor: '#FFFFFF' }}>
                                <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                                    <Link to="/product" style={{ color: '#313235', textDecoration: 'none' }}>
                                        Explore
                                    </Link>
                                </Button>
                            </Card.Footer>
                        </Card>
                        <Card className="border-0">
                            <Card.Img variant="top" src={img4} className="img-fluid" style={{ maxHeight: '64vh' }} />
                        </Card>
                    </CardGroup>
                </div>
            </div>
        </>
    );
}

export default GroupExample;
