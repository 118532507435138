import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import firstkitchen from './img/firstkitchen.png';
import secondkitchen from './img/secondkitchen.png';
import kitchenfirst from './img/kitechenfirst.png';
import kitchensecond from './img/kitchensecond.png';
import kthird from './img/New folder/kthird.png';
import kfourth from './img/New folder/kfourth.png';
import kthird2 from './img/New folder/kthird2.png';
import kthird3 from './img/New folder/kthird3.png';
import kthird4 from './img/New folder/kthird4.png';
import kfourth1 from './img/New folder/kfourth1.png';
import kfourth2 from './img/New folder/kfourth2.png';
import kfourth3 from './img/New folder/kfourth3.png';

const MyComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]); // State to keep track of the current set of images

  const handleShowModal = (images) => {
    setCurrentImages(images); // Set the current set of images when the modal is opened
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={firstkitchen} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Meet the FIZLOZ Grinder Mill: Your Ultimate Kitchen Companion for Effortless Herb and Spice Grinding!"

</Card.Title>
                <Card.Text>
                "Elevate your culinary experience with the FIZLOZ Grinder Mill. Crafted from durable stainless steel, effortlessly grind herbs and spices to perfection. Say goodbye to tedious chopping - upgrade your cooking with ease and precision!"
</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44wza8B" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([kitchenfirst])} style={{  color:'#313235',border:'none',backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={secondkitchen} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>
"Introducing the PLANCK Combo: Get Twice the Convenience with our Buy 1 Get 1 Free Offer on 3-Section Container & Dry-Fruit Cutter!"</Card.Title>
                <Card.Text>

                "Upgrade your kitchen with the PLANCK Combo: 3-Section Container & Dry-Fruit Cutter. Experience ultimate convenience and grab our exclusive Buy 1 Get 1 Free offer today!"                     </Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/4dzilOe" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([kitchensecond])} style={{ color:'#313235',border:'none',backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={kfourth} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>"Effortlessly Craft Perfect Melon Balls with STOREVISE's 4-in-1 Melon Baller Scoop Set"


</Card.Title>
                <Card.Text>
                "Upgrade your fruit carving game with STOREVISE's 4-in-1 Melon Baller Scoop Set. Craft perfect melon balls effortlessly with its stainless steel tools designed for precision and convenience. Say goodbye to messy cuts and hello to beautifully shaped fruit creations. Order now and elevate your culinary experience!"

</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/44BwGpo" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([kthird2,kthird3,kthird4])} style={{  color:'#313235',border:'none',backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3">
              <Card.Img variant="top" src={kthird} className="img-fluid" />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title>
                "Maximize Your Fridge Space with Stysol's Fridge Storage Boxes & Bins Organizer Set!"

</Card.Title>
                <Card.Text>

                "Optimize your refrigerator's storage capacity with Stysol's Space Saving Fridge Storage Boxes and Bins Set. This pack includes a convenient lid and three bins, each offering 5 liters of storage. Keep your fridge organized and clutter-free with this essential storage solution. Get yours now!"






</Card.Text>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Button className="btn-main" style={{ backgroundColor: '#98BE5F', border: '#313235', width: '100px' }}>
                    <Link to="https://amzn.to/3UXeYd2" style={{ color: '#313235', textDecoration: 'none' }}>
                      Buy Now
                    </Link>
                  </Button>
                  <Button variant="primary" onClick={() => handleShowModal([kfourth1,kfourth2,kfourth3])} style={{ color:'#313235',border:'none',backgroundColor: '#98BE5F',marginLeft: '10px' }}>
                    Images
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={currentImages[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} className="img-fluid mr-2" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
            Prev
          </Button>
          <Button variant="primary" onClick={handleNextImage} disabled={currentImageIndex === currentImages.length - 1}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyComponent;
